import React from 'react'
import Img from 'gatsby-image'
import Link from './Link'
import blogpostTypes from '../../blogPageTypes.json'
import LocaleContext from '../contexts/LocaleContext'
import NewPosts from '../components/NewPosts'
import clip from 'text-clipper'

function LatestNews({ data }) {
    const locale = React.useContext(LocaleContext).language
    
    function clippedNews(news) {
        news.forEach(article => {
        article.node.excerpt = clip(article.node.excerpt, 160)
        })
        return news
    }

    return (
    <div>
       {<section className="container center-width prose margin-top">
            <h2>{locale=='sv' ? 'Nyheter från Nordic Asia' : 'News'}</h2>
            <div className="small padding-top-small">
                <NewPosts posts={clippedNews(data)} />
                <div className="flex flex--right ">
                <Link to={ `/${locale}/press/1`} skipLocalePrefix className="btn btn--outline margin-top-small">
                    {locale=='sv' ? 'Läs mer' : 'Read more'}
                </Link>
                </div>
            </div>
            </section>}
    </div>
    )
}

export default LatestNews
