import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'

import ContactUs from '../components/ContactUs'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import Link from '../components/Link'
import ShorterContent from '../components/ShorterContent'
import VideoNews from '../components/VideoNews'
import Faq from '../components/Faq'
import HighlightedMembers from '../components/HighlightedMembers'
import StockInfo from '../components/StockInfo'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
import LocaleContext from '../contexts/LocaleContext'
import LatestNews from '../components/LatestNews'


export default function Index({ data, pageContext }) {

  const {
    seoMetaTags,
    heroText,
    heroBackground,
    introText,
    introImage,
    whyChinaText,
    highlightedMembers,
    videosTitle,
    videoNews,
    faq
  } = data.datoCmsNewHome

  return (
    <Layout locale={pageContext.locale} >
      <React.Fragment>
        <Menu
          transparent={true}
          menu={data.datoCmsNewMenu}
          socialMedia={data.datoCmsFooter.socialMedia}
        />
        <HelmetDatoCms seo={seoMetaTags}>
          <title>Nordic Asia Investment Group</title>
        </HelmetDatoCms>
        <Hero heroBackground={heroBackground}>
          <div
            dangerouslySetInnerHTML={{
              __html: heroText,
            }} />

          <StockInfo />
        </Hero>

        <section className="container center-width prose margin-top">
          <ShorterContent>
            <div
              className="text-justified"
              dangerouslySetInnerHTML={{
                __html: introText,
              }}
            ></div>
            <div className='flex flex--h-center'>
              <AboutButton />
            </div>
            <Img fluid={introImage.fluid} className="margin-top-big" />
              <PortfolioButton />
          </ShorterContent>
        </section>

        <section className='container center-width'>
          <ShorterContent>
            <div className='prose'
              dangerouslySetInnerHTML={{
                __html: whyChinaText,
              }}
            ></div>
          </ShorterContent>
        </section>

        <HighlightedMembers members={highlightedMembers} />

        <LatestNews data={data.allDatoCmsPost.edges} />

        <div className="gray-background ">
          <section className="container center-width prose">
            <h2 className="margin-top-small">{videosTitle}</h2>
            <VideoNews news={videoNews} className="margin-top margin-bottom-big" />
          </section>
        </div>

        <Faq faq={faq} />

        <div className="container center-width">
          <ContactUs data={data.datoCmsContact} />
        </div>
        <Footer footer={data.datoCmsFooter} />
      </React.Fragment>
    </Layout>
  )
}

const AboutButton = () => {
  const language = React.useContext(LocaleContext).language
  return (
    <Link className="btn margin-top-small" skipLocalePrefix={true}
      to={language=="en" ? '/en/about/NordicAsia' : '/about/NordicAsia'}>
        {language=="sv" ? 'Läs mer om Nordic Asia' : 'Read more about Nordic Asia'}
    </Link >
  )
}

const PortfolioButton = () => {
  const language = React.useContext(LocaleContext).language
  return (
    <div className='flex flex--h-center'>
      <Link className="btn margin-top-small" skipLocalePrefix={true}
      to={language=="en" ? '/en/portfolio/portfolioHoldings' : '/portfolio/portfolioHoldings'}>
        {language=="sv" ? 'Läs mer om portföljen' : 'Read more about our portfolio'}
      </Link >
    </div>
  )
}

export const query = graphql`
  query($locale: String!) {
    datoCmsNewHome( locale: { eq: $locale }) {
      seoMetaTags {
        tags
      }
      heroText
      heroBackground {
        fluid(maxWidth: 2560, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      introText
      introImage {
        fluid(maxWidth: 1000, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      introDownload {
        id
        cta
        header
      }
      whyChinaText
      highlightedMembers {
        id
        highlightedMember
        link
        memberMoreInfo {
          name
          introductionNode {
            childMarkdownRemark {
              html
            }
          }
          id
          title
          photo {
            fluid(maxWidth: 700) {
              ...GatsbyDatoCmsFluid
            }
          }
          interview {
            slug
          }
        }
      }
      whyChinaTitle
      whyChina {
        ... on DatoCmsImage {
          __typename
          id
          image {
            fluid(maxWidth: 700, imgixParams: { fm: "png" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
        ... on DatoCmsHtmlText {
          __typename
          id
          text
        }
      }
      whyChinaDownload {
        id
        cta
        header
      }
      videosTitle
      videoNews {
        id
        spotifyLink
        videoLink
        oembedCode
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      faq {
        id
        title
        content
      }
    }
    datoCmsContact (locale: { eq: $locale }){
      ...Contact
    }
    datoCmsNewMenu(locale: { eq: $locale }) {
      ...Menu
    }
    datoCmsFooter (locale: { eq: $locale }) {
      ...Footer
    }
    allDatoCmsPost(
      filter: { locale: { eq: $locale }, postType: { eq: "press" } }
      limit: 2
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
    insightPosts: allDatoCmsPost (
      filter: { locale: { eq: "en" }, postType: { eq: "blog" } }
      limit: 2
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
  }
`
